import React from 'react';
import Meta from '../../components/Meta';
import Hero_4 from '../../components/hero/hero_4';
import CoverflowCarousel from '../../components/carousel/coverflowCarousel';

const HomeIndex = () => {
	return (
		<>
			<Meta title="Alpha-Pharm || Home Page" />
			<Hero_4 />
			<CoverflowCarousel />
			{/* <NewseLatter bgWhite={true} />
			<Partners /> */}
		</>
	);
};

export default HomeIndex;
