import React from "react";

const Hero_4 = () => {

  return (
    <>
      {/* <!-- Hero --> */}
      <section className="relative py-20 hero md:pt-32">
        <picture className="absolute inset-x-0 top-0 pointer-events-none -z-10 dark:hidden">
          <img src="/images/gradient.jpg" alt="gradient" />
        </picture>
        <picture className="absolute inset-x-0 top-0 hidden pointer-events-none -z-10 dark:block">
          <img src="/images/gradient_dark.jpg" alt="gradient dark" />
        </picture>

        <div className="container">
          <div className="max-w-2xl pt-24 mx-auto text-center">
            <h1 className="mb-10 text-5xl font-display text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl">
            Welcome to <br></br>
              <span className="animate-gradient">  Alpha-Pharm</span>
            </h1>
          </div>
        </div>
      </section>
      {/* <!-- end hero --> */}
    </>
  );
};

export default Hero_4;
